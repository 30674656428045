import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Card, Chip, TextField, Typography } from '@mui/material';
import { OneMedia, useAdvice } from '@prismamedia/one-components';
import { EditorState, RawDraftContentState } from 'draft-js';
import { getSelectionEntity } from 'draftjs-utils';
import { findKey, isUndefined } from 'lodash';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import { ArticleFormat, BrandKey } from '../../../__generated__/queries-web';
import { Draft } from '../../../components/Draft';
import { CharCounter } from '../../../components/Draft/CharCounter';
import {
  PossibleActions,
  actions,
  actionsCustom,
} from '../../../components/Draft/DraftActions';
import { MediaInput } from '../../../components/MediaInput';
import { TextSuggestion } from '../../../components/TextSuggestion';
import { brandsOptions } from '../../../utils/brands';
import {
  ArticleEditAction,
  ArticleState,
  actions as reducerActions,
} from '../../ArticleEdit/reducer';
import {
  renderMediaFromTemplate,
  renderPluginsFromTemplate,
} from '../../ArticleEdit/utils/renderFromTemplate';
import { useGetHandlePastedFn } from './handlePaste';
import {
  ArticleEditorContentPopper,
  ArticleEditorLeadPopper,
  ArticleEditorTitlePopper,
} from './poppers';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ReactGA from 'react-ga4';
import { AffilizzMediaPlugin } from '../plugins/affilizz';
import { AuthorsInput } from './AuthorsInput';
import { SlugInput } from './SlugInput';
import { LINK_TITLE_IA_SUGGESTIONS } from './constants';
import { useStyles } from './styles';

type ArticleEditorProps = {
  articleEditWrapperRef: MutableRefObject<HTMLElement | null>;
  renderInput: (inputName: string) => boolean;
  dispatch: Dispatch<ArticleEditAction>;
  subjectId?: string;
  publicUrl: string | null;
  SourceComponent: React.ReactNode | null;
  CategoriesComponent: React.ReactNode | null;
} & Pick<
  ArticleState,
  | 'id'
  | 'title'
  | 'secondaryTitle'
  | 'partnerTitle'
  | 'medias'
  | 'format'
  | 'brandKey'
  | 'lead'
  | 'body'
  | 'platformId'
  | 'status'
  | 'articleAuthors'
  | 'slug'
  | 'articleUrls'
>;

const getIsEntityMapHasPersonLink = (entityMap: any) =>
  !isUndefined(findKey(entityMap, ['type', PossibleActions.PERSON_LINK]));

const getLinkTitleIa = (body: RawDraftContentState | null) => {
  const contentArticle = body?.blocks?.reduce((accum, line) => {
    if (line.text.length) {
      return `${accum} ${line.text
        .replace(/[’]/g, "'")
        .replace(/[^\wéèêëîïôöùûüàáâç,.?!;:'\n\s]|_/g, '')} `;
    }
    return accum;
  }, '');
  const base64data = new Buffer(contentArticle ?? '').toString('base64');
  return `${LINK_TITLE_IA_SUGGESTIONS}&text=${base64data}`;
};

export const ArticleEditor: FC<ArticleEditorProps> = memo(
  ({
    id,
    articleEditWrapperRef,
    title,
    secondaryTitle,
    partnerTitle,
    medias,
    lead,
    body,
    renderInput,
    format,
    brandKey,
    dispatch,
    subjectId,
    platformId,
    publicUrl,
    articleAuthors,
    slug,
    articleUrls,
    CategoriesComponent = null,
    SourceComponent = null,
  }) => {
    const classes = useStyles();
    const { openAdvice } = useAdvice();
    const contentFieldRef = useRef(null);
    const leadFieldRef = useRef(null);
    const titleFieldRef = useRef(null);
    const partnerTitleFieldRef = useRef<HTMLDivElement | null>(null);
    const [showPersonLinkAction, setShowPersonLinkAction] = useState<boolean>(
      getIsEntityMapHasPersonLink(body?.entityMap),
    );

    const [isActiveSlug, setIsActiveSlug] = useState<boolean>(false);
    const [
      suggestMenuAnchor,
      setSuggestMenuAnchor,
    ] = useState<HTMLDivElement | null>(null);
    const { isCerise } = brandsOptions[brandKey];

    const setMedias = (updater: SetStateAction<OneMedia[]>) => {
      const newMedias =
        typeof updater === 'function' ? updater(medias || []) : updater;

      dispatch(
        reducerActions.updateField({ field: 'medias', value: newMedias }),
      );
    };

    const handleInfoIconClick = useCallback(
      (anchorRef, content) => () => {
        openAdvice({
          anchorRef,
          content,
          PopperProps: {
            container: articleEditWrapperRef?.current,
            keepMounted: false,
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundariesElement: 'window',
                  enabled: false,
                  escapeWithReference: true,
                },
              },
            ],
            style: { width: '310px' },
          },
          zIndex: 1,
        });
      },
      [articleEditWrapperRef, openAdvice],
    );

    const handlePastedBody = useGetHandlePastedFn({
      dispatch,
      field: 'body',
    });

    const handleDraftSelection = useCallback((editorState: EditorState) => {
      let entity;

      const currentContent = editorState.getCurrentContent();
      const entityKey = getSelectionEntity(editorState);

      if (entityKey) {
        entity = currentContent.getEntity(entityKey);
      }

      entity?.getType() &&
        setShowPersonLinkAction(
          entity.getType() === PossibleActions.PERSON_LINK,
        );
    }, []);

    if (!brandKey) {
      return <Typography>Erreur, il n'y a pas de BrandKey</Typography>;
    }

    const getPlugins = () => {
      const enabledBrandsKeys = [BrandKey.CAC, BrandKey.CAP, BrandKey.FAC];

      const affilizPlugin = enabledBrandsKeys.find(
        (enabledBrand) => enabledBrand === brandKey,
      )
        ? AffilizzMediaPlugin(brandKey, id, format, dispatch)
        : null;

      const defaultPlugins = renderPluginsFromTemplate(brandKey);

      if (affilizPlugin) {
        // eslint-disable-next-line fp/no-mutating-methods
        defaultPlugins.push(affilizPlugin);
      }

      return defaultPlugins;
    };

    const defaultPlugins = getPlugins();

    const allowedMedias = renderMediaFromTemplate(format, brandKey);
    const isVideo = format === ArticleFormat.Video;

    const { displayNewModule } = brandsOptions[brandKey];
    const linkTitleIa = getLinkTitleIa(body);

    const displayButtonTitleIa =
      !isVideo && brandsOptions[brandKey].displayIATitle;

    const renderTitle = () => {
      if (!renderInput('title')) return null;

      return (
        <div className={classes.formGroup}>
          <TextField
            data-testid="title"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: displayButtonTitleIa && (
                <Chip
                  label="IA"
                  target="_blank"
                  component="a"
                  href={linkTitleIa}
                  variant="outlined"
                  color="default"
                  clickable
                  className={classes.textFieldChip}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Article Edit',
                      action:
                        'Récupérer des suggestions de titres avec ChatGPT',
                      label: window.location.href,
                    });
                  }}
                  avatar={<AutoFixHighIcon />}
                />
              ),
            }}
            label={
              <div className={classes.textFieldLabelIcon}>
                <div>Titre</div>
                <InfoIcon
                  onClick={handleInfoIconClick(
                    titleFieldRef,
                    ArticleEditorTitlePopper,
                  )}
                  ref={titleFieldRef}
                />
              </div>
            }
            multiline
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(
                reducerActions.updateField({
                  field: 'title',
                  value: e.currentTarget.value,
                }),
              );
            }}
            value={title}
            variant="standard"
          />
          <CharCounter content={title} />
        </div>
      );
    };

    return (
      <>
        <div className={classes.root}>
          {CategoriesComponent}
          {displayNewModule ? (
            <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              {renderTitle()}
              {renderInput('slug') && isActiveSlug && (
                <>
                  <SlugInput slug={slug} dispatch={dispatch} />
                  {articleUrls.map(({ url }, index) => (
                    <TextField
                      disabled
                      key={index}
                      data-testid="urls"
                      fullWidth
                      label="Urls"
                      multiline
                      value={url.path}
                      variant="standard"
                      sx={{ mt: 2 }}
                    />
                  ))}
                </>
              )}
              <Button
                onClick={() => setIsActiveSlug((prev) => !prev)}
                sx={{ alignSelf: 'flex-end', mt: 2 }}
                startIcon={isActiveSlug ? <ExpandLess /> : <ExpandMore />}
              >
                {isActiveSlug ? 'Masquer le slug' : 'Afficher le slug'}
              </Button>
            </Card>
          ) : (
            renderTitle()
          )}
          {renderInput('secondaryTitle') && (
            <TextField
              fullWidth
              label="Titre secondaire"
              multiline
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  reducerActions.updateField({
                    field: 'secondaryTitle',
                    value: e.currentTarget.value,
                  }),
                );
              }}
              value={secondaryTitle || ''}
              variant="standard"
            />
          )}
          {renderInput('partnerTitle') && (
            <TextField
              data-testid="partnerTitle"
              fullWidth
              label={
                brandKey !== BrandKey.GAL
                  ? "Titre d'accroche"
                  : 'Titre partenaire'
              }
              multiline
              onClick={() => {
                if (title && !suggestMenuAnchor) {
                  setSuggestMenuAnchor(partnerTitleFieldRef.current);
                }
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  reducerActions.updateField({
                    field: 'partnerTitle',
                    value: e.currentTarget.value,
                  }),
                );
              }}
              value={partnerTitle || ''}
              variant="standard"
              ref={partnerTitleFieldRef}
            />
          )}
          {brandKey !== BrandKey.GAL && title && suggestMenuAnchor && (
            <TextSuggestion
              anchorRef={suggestMenuAnchor}
              brandKey={brandKey}
              handleClose={() => setSuggestMenuAnchor(null)}
              partnerTitle={partnerTitle}
              title={title}
              onConfirm={(suggestion: string) =>
                dispatch(
                  reducerActions.updateField({
                    field: 'partnerTitle',
                    value: suggestion,
                  }),
                )
              }
            />
          )}
          {renderInput('medias') && (
            <MediaInput
              medias={medias || []}
              title={isVideo ? 'Ajouter une vidéo' : undefined}
              disableFields={isVideo}
              allowedMedias={allowedMedias}
              brandKey={brandKey}
              setMedias={setMedias}
              subjectId={subjectId}
              videoId={platformId || undefined}
              articleLead={lead}
              articleTitle={title}
              articleFormat={format}
            />
          )}
          {renderInput('lightDraft') && (
            <div className={classes.formGroup}>
              <Draft
                placeholder={
                  <div className={classes.textFieldLabelIcon}>
                    <div>Chapô de l'article</div>
                    <InfoIcon
                      onClick={handleInfoIconClick(
                        leadFieldRef,
                        ArticleEditorLeadPopper,
                      )}
                      ref={leadFieldRef}
                    />
                  </div>
                }
                rawDraft={lead}
                onChange={(value) => {
                  dispatch(
                    reducerActions.updateField({
                      field: 'lead',
                      value,
                    }),
                  );
                }}
                actions={[
                  actions.BOLD,
                  actions.ITALIC,
                  actions.LINK,
                  actions.INTERNAL_LINK,
                ]}
              />
            </div>
          )}
          {renderInput('richDraft') && (
            <div className={classes.formGroup}>
              <Draft
                placeholder={
                  <div className={classes.textFieldLabelIcon}>
                    <div>Contenu de l'article</div>
                    <InfoIcon
                      onClick={handleInfoIconClick(
                        contentFieldRef,
                        ArticleEditorContentPopper,
                      )}
                      ref={contentFieldRef}
                    />
                  </div>
                }
                rawDraft={body}
                onChange={(value) => {
                  dispatch(
                    reducerActions.updateField({
                      field: 'body',
                      value,
                    }),
                  );
                }}
                onSelection={handleDraftSelection}
                actions={[
                  actions.BOLD,
                  actions.ITALIC,
                  actions.STRIKETHROUGH,
                  actions.LINK,
                  actions.ANCHOR_LINK,
                  actions.INTERNAL_LINK,
                  actions.TITLE,
                  actions.HEADLINE,
                  actions.LIST,
                  actions.ORDERED_LIST,
                  actions.BLOCKQUOTE,
                  actionsCustom.COPY_ANCHORS(publicUrl ?? ''),
                  ...(showPersonLinkAction
                    ? [actions.SEPARATOR, actions.PERSON_LINK]
                    : []),
                ]}
                plugins={defaultPlugins}
                handlePastedText={handlePastedBody}
              />
            </div>
          )}
          {displayNewModule && (
            <div className={classes.formGroup}>
              <AuthorsInput
                authors={articleAuthors}
                brandKey={brandKey}
                dispatch={dispatch}
                requiredInput={isCerise}
              />
            </div>
          )}
          {SourceComponent}
        </div>
      </>
    );
  },
);
