import { Theme } from '@mui/material';
import {
  cardTheme,
  defaultTheme,
  diapoTheme,
  pageTheme,
  tagTheme,
  videoTheme,
} from '../muiThemes';
import { isArticleVideo, isCard, isOneWeb, isPage, isPush, isTag } from './url';

export const getAppTheme = (pathname: string): Theme => {
  const currentApp = true;
  switch (currentApp) {
    case isArticleVideo(pathname):
      return videoTheme;
    case isOneWeb(pathname):
    case isPush(pathname):
    case pathname === '/dashboard':
      return defaultTheme;
    case isCard(pathname):
      return cardTheme;
    case isPage(pathname):
      return pageTheme;
    case isTag(pathname):
      return tagTheme;
    default:
      return diapoTheme;
  }
};
