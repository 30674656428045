import { Add, Description, LocalOfferOutlined } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';
import {
  AppName,
  countSearchParams,
  HomeAppBar,
  InfiniteTable,
  PageWithDrawer,
  SearchBar,
  useOnMount,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';
import {
  BrandKey,
  GetTags_tags,
  TagOrderByInput,
} from '../../__generated__/queries-web';
import { OneDrawerMenu } from '../../components/AppBar';
import { BrandSwitch } from '../../components/BrandSwitch';
import { TagIcons } from '../../components/Tags/TagItem/Thumbnail/constants';
import { paths } from '../../routing/Router/paths';
import { auth } from '../../utils/auth';
import { useGlobalBrandKey } from '../../utils/globalState';
import {
  AdvancedSearch,
  AdvancedSearchParams,
  TAG_STATUT,
} from './AdvancedSearch';
import { useGetTags } from './tags.web.graphql';
import { allowedTagTypes } from './utils';

export const TagList: FC = () => {
  const history = useHistory();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [searchParams, setSearchParams] = useState<AdvancedSearchParams>({});
  const [brandKeyUrl, setBrandKeyUrl] = useQueryParam('brand', StringParam);
  const [searchUrl, setSearchUrl] = useQueryParam('search', JsonParam);
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);

  useOnMount(() => {
    brandKeyUrl && setBrandKey(brandKeyUrl as BrandKey);
    searchUrl && setSearchParams(searchUrl);
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setBrandKeyUrl(brandKey, 'replaceIn');
  }, [brandKey, setBrandKeyUrl]);

  useEffect(() => {
    setSearchUrl(
      countSearchParams(searchParams) ? searchParams : undefined,
      'replaceIn',
    );
  }, [searchParams, setSearchUrl]);

  const { data, loading, error, fetchMore } = useGetTags(
    {
      first: 100,
      skip: 0,
      where: {
        OR: [
          { title_contains: searchParams.search },
          { slug_contains: searchParams.search },
        ],
        type: searchParams.type,
        type_in: allowedTagTypes,
        tagBrandUsages_some: {
          brandKey,
          isEnabled: searchParams.status
            ? searchParams.status === TAG_STATUT.ENABLED
              ? true
              : false
            : undefined,
        },
      },
      orderBy: [TagOrderByInput.title_ASC],
    },
    loadingUrlParams,
  );

  return (
    <>
      <HomeAppBar
        currentUser={auth.user}
        disconnectUser={auth.logout}
        appDrawerMenu={
          <OneDrawerMenu app={AppName.Tags} currentBrand={brandKey} />
        }
        brandMenu={
          <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
        }
        searchBar={
          searchParams && setSearchParams ? (
            <SearchBar
              advancedSearch={{
                component: AdvancedSearch,
              }}
              placeholder={`Rechercher un tag`}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          ) : undefined
        }
      />
      <PageWithDrawer fullWidth sx={{ height: '100%' }}>
        <></>
        <InfiniteTable
          rowHeight={70}
          list={data?.tags}
          loading={loading}
          error={error}
          threshold={50}
          fetchMore={({ startIndex }) =>
            fetchMore({
              variables: {
                skip: startIndex,
              },
            })
          }
          columns={[
            {
              label: 'Intitulé',
              key: 'title',
              width: 300,
              flexGrow: 0,
            },

            {
              label: 'Type',
              key: 'type',
              cellRenderer: (rowData: GetTags_tags) => {
                const Icon = TagIcons[rowData.type] || LocalOfferOutlined;
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Icon sx={{ fontSize: 16, mr: 1 }} />
                    <Typography sx={{ fontSize: 14 }}>
                      {rowData.type}
                    </Typography>
                  </Box>
                );
              },
              width: 130,
              flexGrow: 0,
            },
            {
              label: 'Marque',
              key: 'brandKeys',
              cellRenderer: (rowData: GetTags_tags) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxHeight: '100%',
                    overflow: 'auto',
                    py: 0.3,
                    px: 1,
                  }}
                >
                  {rowData.tagBrandUsages.map((tagBrandUsage) => (
                    <Chip
                      key={tagBrandUsage.brandKey}
                      component="a"
                      href={`#${generatePath(paths.TAG_EDIT, {
                        id: rowData.id,
                        brandKey: tagBrandUsage.brandKey,
                      })}`}
                      onClick={() =>
                        history.push(
                          generatePath(paths.TAG_EDIT, {
                            id: rowData.id,
                            brandKey: tagBrandUsage.brandKey,
                          }),
                        )
                      }
                      size="small"
                      label={
                        <>
                          <Box
                            component="span"
                            sx={{ fontWeight: 'bold', mr: 0.5 }}
                          >
                            {tagBrandUsage.brandKey}
                          </Box>
                          <Box component="span">
                            ({tagBrandUsage.articleCount})
                          </Box>
                        </>
                      }
                      sx={{
                        m: 0.3,
                        bgcolor: tagBrandUsage.isEnabled
                          ? undefined
                          : '#f3cfce',
                      }}
                      icon={
                        rowData.cardTags.find(
                          (cardTag) =>
                            cardTag.card.brandKey === tagBrandUsage.brandKey,
                        ) ? (
                          <Description />
                        ) : (
                          <Add />
                        )
                      }
                    />
                  ))}
                </Box>
              ),
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Articles',
              key: 'articleTagCount',
              width: 80,
              flexGrow: 0,
            },
            {
              label: 'Articles publiés',
              key: 'publishedArticleTagCount',
              width: 70,
              flexGrow: 0,
              cellRenderer: (rowData: GetTags_tags) => {
                const articleLink = `#${paths.ARTICLE_LIST}?&search=%7B"tags"%3A%5B%7B"id"%3A"${rowData.id}"%2C"label"%3A"${rowData.title}"%2C"secondaryLabel"%3A"Tag%20-%202667%20articles"%2C"isInactive"%3Afalse%2C"selected"%3Afalse%7D%5D%7D&status=Published`;
                return (
                  <Chip
                    label={rowData.publishedArticleTagCount}
                    sx={{ mx: 'auto' }}
                    component="a"
                    target="_blank"
                    href={articleLink}
                    onClick={() => history.push(articleLink)}
                  />
                );
              },
            },

            {
              label: 'Slug',
              key: 'slug',
              width: 200,
              flexGrow: 0,
            },
          ]}
        />
      </PageWithDrawer>
    </>
  );
};
