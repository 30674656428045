import { brandTitleByKey } from '@prismamedia/one-brandkey';
import {
  BrandKey,
  TagProviderName,
  TagSearchTypes,
} from '../__generated__/queries-web';
import { InternalLinkType } from '../components/InternalLinkModal/InternalLink';
import { MEDIA_TYPES } from '../components/MediaInput/constants';
import { PluginType } from '../pages/ArticleEdit/templates/utils';

const BASE_RICH_FIELDS = [
  'publishedAt',
  'title',
  'partnerTitle',
  'medias',
  'lightDraft',
  'richDraft',
  'firstCategory',
  'tags',
  'slug',
];

const RICH_MEDIA_TYPES = [
  MEDIA_TYPES.IMAGE,
  MEDIA_TYPES.EMBED,
  MEDIA_TYPES.ARTICLE_VIDEO,
  MEDIA_TYPES.SLIDESHOW,
  MEDIA_TYPES.PODCAST,
  MEDIA_TYPES.FOTOWEB,
];

interface BrandOptions {
  affiliate?: boolean;
  isCerise?: boolean;
  disabledForCerise?: boolean;
  displayIATitle?: boolean;
  displayNewModule?: boolean;
  draft?: {
    richFields: string[];
    plugins: PluginType[];
  };
  editorialAlertAllowed?: boolean;
  internalLinks?: InternalLinkType[];
  medias?: {
    richTypes: MEDIA_TYPES[];
  };
  mediaProgramsAllowed?: boolean;
  personTagsAllowed?: boolean;
  pushPlatforms?: ('android' | 'ios')[];
  setInitialTitleAllowed?: boolean;
  tagProviders: TagProviderName[];
  useFotoweb?: boolean;
  tagTypes?: TagSearchTypes[];
}

export const brandsOptions: Record<BrandKey, BrandOptions> = {
  BEA: {
    draft: {
      richFields: BASE_RICH_FIELDS,
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    internalLinks: [InternalLinkType.ARTICLE, InternalLinkType.PRODUCT],
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  BIN: {
    displayNewModule: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondaryTitle', 'authors', 'source'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  BONE: {
    tagProviders: [TagProviderName.Tag],
  },
  BRGTS: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  BROMM: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  CAC: {
    affiliate: true,
    displayNewModule: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'event', 'source', 'guides'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.PageBreak,
        PluginType.Table,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE, InternalLinkType.RECIPE],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  CAM: {
    displayNewModule: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondaryTitle', 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    pushPlatforms: ['ios', 'android'],
    setInitialTitleAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  CAP: {
    affiliate: true,
    displayNewModule: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'event', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.PageBreak,
        PluginType.C2C,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.Company,
    ],
    useFotoweb: true,
  },
  CTR: {
    tagProviders: [TagProviderName.Tag],
  },
  DEGTS: {
    isCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  DEOMM: {
    isCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  DESAS: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'event', 'source', 'guides'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  ESGTS: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  ESOMM: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  FAC: {
    affiliate: true,
    displayNewModule: true,
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'authors',
        'event',
        'source',
      ],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.PageBreak,
        PluginType.Table,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE, InternalLinkType.RECIPE],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    setInitialTitleAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FRGSD: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FRGSG: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FRGSL: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FRGSS: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FRGSV: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  FROMM: {
    affiliate: true,
    isCerise: true,
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  GAL: {
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'secondCategory',
        'authors',
        'event',
      ],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
  },
  GEO: {
    affiliate: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source', 'event'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.C2C,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    pushPlatforms: ['ios', 'android'],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Destination,
      TagProviderName.Person,
    ],
    useFotoweb: true,
  },
  HBR: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondaryTitle', 'authors'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  HBZ: {
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'secondCategory',
        'authors',
        'source',
        'event',
      ],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.PageBreak,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.SLIDESHOW,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  ITGTS: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  ITOMM: {
    isCerise: true,
    disabledForCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  NEO: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondaryTitle', 'authors', 'source'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  SHGFAC: {
    affiliate: true,
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'authors',
        'event',
        'source',
      ],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.PageBreak,
        PluginType.Table,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
  SIM: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondaryTitle', 'authors'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  TEL: {
    affiliate: true,
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondCategory',
        'source',
        'authors',
        'event',
      ],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Table,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    mediaProgramsAllowed: true,
    internalLinks: [
      InternalLinkType.ARTICLE,
      InternalLinkType.PROGRAM,
      InternalLinkType.BROADCAST,
    ],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.TvBy,
      TagProviderName.TvCollection,
    ],
    tagTypes: [
      TagSearchTypes.Broadcast,
      TagSearchTypes.Cinema,
      TagSearchTypes.VOD,
    ],
    useFotoweb: true,
  },
  TESTBRAND: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondCategory', 'authors'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    mediaProgramsAllowed: true,
    internalLinks: [
      InternalLinkType.ARTICLE,
      InternalLinkType.PROGRAM,
      InternalLinkType.BROADCAST,
    ],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.TvBy,
      TagProviderName.TvCollection,
    ],
    tagTypes: [
      TagSearchTypes.Broadcast,
      TagSearchTypes.Cinema,
      TagSearchTypes.VOD,
    ],
    useFotoweb: true,
  },
  TGC: {
    displayIATitle: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondCategory', 'authors'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Table,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    mediaProgramsAllowed: true,
    internalLinks: [
      InternalLinkType.ARTICLE,
      InternalLinkType.PROGRAM,
      InternalLinkType.BROADCAST,
    ],
    pushPlatforms: ['ios', 'android'],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.TvBy,
      TagProviderName.TvCollection,
    ],
    tagTypes: [
      TagSearchTypes.Broadcast,
      TagSearchTypes.Cinema,
      TagSearchTypes.VOD,
    ],
    useFotoweb: true,
  },
  TLA: {
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'secondCategory',
        'authors',
      ],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.TvBy,
      TagProviderName.TvCollection,
    ],
    tagTypes: [
      TagSearchTypes.Broadcast,
      TagSearchTypes.Cinema,
      TagSearchTypes.VOD,
    ],
    useFotoweb: true,
  },
  TLS: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'secondCategory', 'authors'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Table,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    mediaProgramsAllowed: true,
    internalLinks: [
      InternalLinkType.ARTICLE,
      InternalLinkType.PROGRAM,
      InternalLinkType.BROADCAST,
    ],
    pushPlatforms: ['ios', 'android'],
    tagProviders: [
      TagProviderName.Tag,
      TagProviderName.Person,
      TagProviderName.TvBy,
      TagProviderName.TvCollection,
    ],
    tagTypes: [
      TagSearchTypes.Broadcast,
      TagSearchTypes.Cinema,
      TagSearchTypes.VOD,
    ],
    useFotoweb: true,
  },
  UKGTS: {
    isCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  UKOMM: {
    isCerise: true,
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'source'],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: [
        MEDIA_TYPES.IMAGE,
        MEDIA_TYPES.EMBED,
        MEDIA_TYPES.ARTICLE_VIDEO,
        MEDIA_TYPES.FOTOWEB,
      ],
    },
    internalLinks: [InternalLinkType.ARTICLE],
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  UKSAS: {
    draft: {
      richFields: [...BASE_RICH_FIELDS, 'authors', 'event', 'source', 'guides'],
      plugins: [PluginType.Media, PluginType.Image, PluginType.Multimedia],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    tagProviders: [TagProviderName.Tag],
    useFotoweb: true,
  },
  VDF: {
    tagProviders: [TagProviderName.Tag],
  },
  VOI: {
    draft: {
      richFields: [
        ...BASE_RICH_FIELDS,
        'secondaryTitle',
        'secondCategory',
        'authors',
        'event',
      ],
      plugins: [
        PluginType.Media,
        PluginType.Image,
        PluginType.Multimedia,
        PluginType.Frame,
        PluginType.C2C,
      ],
    },
    editorialAlertAllowed: true,
    medias: {
      richTypes: RICH_MEDIA_TYPES,
    },
    internalLinks: [InternalLinkType.ARTICLE],
    personTagsAllowed: true,
    pushPlatforms: ['ios', 'android'],
    tagProviders: [TagProviderName.Tag, TagProviderName.Person],
    useFotoweb: true,
  },
};

export const getTitleFromBrandKey = (brandKey: keyof typeof BrandKey | null) =>
  brandKey && brandTitleByKey[brandKey] ? brandTitleByKey[brandKey] : brandKey;

export const getBrandkeys = (predicate: (options: BrandOptions) => boolean) =>
  Object.entries(brandsOptions)
    .filter(([, options]) => predicate(options))
    .map(([key]) => key as BrandKey);
