import { gql, useQuery } from '@apollo/client';
import { GetTags, GetTagsVariables } from '../../__generated__/queries-web';

const GET_TAGS = gql`
  query GetTags(
    $where: TagWhereInput
    $orderBy: [TagOrderByInput!]
    $first: Int!
    $skip: Int
  ) {
    tags(first: $first, skip: $skip, where: $where, orderBy: $orderBy) {
      id
      title
      slug
      type
      tagBrandUsages(first: 100, orderBy: [brandKey_ASC]) {
        brandKey
        isEnabled
        articleCount
      }
      cardTags(first: 100) {
        card {
          id
          brandKey
        }
      }
      publishedArticleTagCount: articleTagCount(
        where: { article: { status: Published } }
      )
      articleTagCount
    }
  }
`;

export const useGetTags = (variables: GetTagsVariables, skip?: boolean) =>
  useQuery<GetTags, GetTagsVariables>(GET_TAGS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip,
  });
