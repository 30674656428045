import { Box } from '@mui/material';
import { AdvancedSearchProps, SimpleSelect } from '@prismamedia/one-components';
import { FC } from 'react';
import { TagType } from '../../__generated__/queries-web';
import { allowedTagTypes } from './utils';

export interface AdvancedSearchParams {
  search?: string;
  type?: TagType;
  status?: TAG_STATUT;
}

export enum TAG_STATUT {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  searchParams,
  updateSearchParam,
}) => {
  return (
    <Box sx={{ display: 'flex', py: 2, px: 3 }}>
      <SimpleSelect
        label="Type"
        sx={{ flex: 1, mr: 2 }}
        options={[
          { value: '', label: '-' },
          ...allowedTagTypes.map((tagType) => ({
            value: tagType,
            label: tagType,
          })),
        ]}
        value={searchParams.type}
        onChange={updateSearchParam('type')}
      />
      <SimpleSelect
        label="Statut"
        sx={{ flex: 1 }}
        options={[
          { value: '', label: '-' },
          { value: TAG_STATUT.ENABLED, label: 'Actif' },
          { value: TAG_STATUT.DISABLED, label: 'Inactif' },
        ]}
        value={searchParams.status}
        onChange={updateSearchParam('status')}
      />
    </Box>
  );
};
