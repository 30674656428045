import { createTheme, Theme } from '@mui/material';
import { common, grey, red } from '@mui/material/colors';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const diapoTheme = createTheme({
  // Overrides MUI components styles
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: 'white',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3f51b5',
      contrastText: common.white,
    },
    secondary: {
      main: '#f50057',
      contrastText: common.white,
    },
  },
});

export const videoTheme = createTheme({
  palette: {
    primary: {
      main: '#ad1457',
      contrastText: common.white,
    },
  },
});

export const defaultTheme = createTheme({
  palette: {
    background: {
      default: '#fafafa',
    },
    primary: {
      main: red[500],
      contrastText: common.white,
    },
  },
});

export const cardTheme = createTheme({
  palette: {
    primary: {
      main: '#26c6da',
      contrastText: common.white,
    },
  },
});

export const pageTheme = createTheme({
  palette: {
    background: {
      default: '#fafafa',
    },
    primary: {
      main: '#0097A7',
      contrastText: common.white,
    },
    secondary: {
      main: grey[800],
      contrastText: common.white,
    },
  },
});

export const tagTheme = createTheme({
  palette: {
    primary: {
      main: '#333e45',
      contrastText: common.white,
    },
  },
});
