import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getOperationName } from 'apollo-link';
import { GET_DEPARTMENTS } from '../crud/user/userEdit/departments.auth.graphql';
import {
  CREATE_USER,
  GET_USER,
  UPDATE_USER,
} from '../crud/user/userEdit/user.auth.graphql';
import { GET_GOOGLE_GROUP } from '../crud/userGroup/userGroupEdit/getGoogleGroup.auth.graphql';
import {
  CREATE_GOOGLE_GROUP,
  UPDATE_GOOGLE_GROUP,
} from '../crud/userGroup/userGroupEdit/updateGoogleGroup.auth.graphql';
import { GET_GOOGLE_GROUPS } from '../crud/userGroup/userGroupList/googleGroups.auth.graphql';
import { GET_ROLE } from '../crud/userRole/userRoleEdit/getUserRole.auth.graphql';
import {
  CREATE_ROLE,
  UPDATE_ROLE,
} from '../crud/userRole/userRoleEdit/updateUserRole.auth.graphql';
import { GET_ROLES } from '../crud/userRole/userRoleList/roles.auth.graphql';
import { GET_ASSIGNMENTS_LINK_TO_TOPIC } from '../pages/ArticleEdit/DrawerContent/LinksToTopic/linksToTopic.topic.graphql';
import {
  CREATE_SUBJECT_FOR_DUPLICATION,
  GET_ASSIGNMENTS_FOR_DUPLICATION,
  GET_PRINT_HEADINGS_FOR_DUPLICATION,
  GET_PRINT_ISSUES_FOR_DUPLICATION,
  GET_PRINT_PUBLICATIONS_FOR_DUPLICATION,
  GET_UNITS_FOR_DUPLICATION,
  UPDATE_ASSIGNMENT_FOR_DUPLICATION,
} from '../pages/ArticleEdit/DuplicatePrintDialog/duplicatePrint.topic.graphql';
import { GET_ASSIGMENT_QUERY } from '../pages/ArticleEdit/queries/getAssigment.topic.graphql';
import { GET_RAW_ARTICLE_QUERY } from '../pages/ArticleEdit/queries/getSubjectQuery.topic.graphql';
import { GET_PEOPLES } from '../pages/Pages/apollo/queries/peoples.people.graphql';
import { auth } from '../utils/auth';
import { GET_FOCUS_POINT } from './queries/focusPoint.image.graphql';
import { GET_PEOPLE_BIO_QUERY } from './queries/getPeopleBio.people.graphql';
import { GET_PHOTOS } from './queries/photos.photo.graphql';
import { GET_RECIPES, RECIPE_COUNT } from './queries/recipes.recipe.graphql';
import { GET_RESOLVED_IMAGE } from './queries/resolvedImage.image.graphql';
import { GET_UPLOAD_URL } from './queries/uploadUrl.image.graphql';
import { GET_USERS } from './queries/users.auth.graphql';
const httpLink = new HttpLink({
  uri: config.API_ONE_WEB,
});

const identifyRequestLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => {
    let baseUri;
    switch (operation.operationName) {
      case getOperationName(GET_RAW_ARTICLE_QUERY):
      case getOperationName(GET_ASSIGMENT_QUERY):
      case getOperationName(GET_ASSIGNMENTS_FOR_DUPLICATION):
      case getOperationName(GET_PRINT_PUBLICATIONS_FOR_DUPLICATION):
      case getOperationName(GET_PRINT_ISSUES_FOR_DUPLICATION):
      case getOperationName(GET_PRINT_HEADINGS_FOR_DUPLICATION):
      case getOperationName(GET_UNITS_FOR_DUPLICATION):
      case getOperationName(CREATE_SUBJECT_FOR_DUPLICATION):
      case getOperationName(UPDATE_ASSIGNMENT_FOR_DUPLICATION):
      case getOperationName(GET_ASSIGNMENTS_LINK_TO_TOPIC):
        baseUri = config.API_ONE_TOPIC;
        break;
      case getOperationName(GET_RECIPES):
      case getOperationName(RECIPE_COUNT):
        baseUri = config.API_ONE_RECIPE;
        break;
      case getOperationName(GET_USER):
      case getOperationName(GET_USERS):
      case getOperationName(UPDATE_USER):
      case getOperationName(CREATE_USER):
      case getOperationName(GET_DEPARTMENTS):
      case getOperationName(GET_ROLES):
      case getOperationName(GET_ROLE):
      case getOperationName(UPDATE_ROLE):
      case getOperationName(CREATE_ROLE):
      case getOperationName(GET_GOOGLE_GROUPS):
      case getOperationName(GET_GOOGLE_GROUP):
      case getOperationName(UPDATE_GOOGLE_GROUP):
      case getOperationName(CREATE_GOOGLE_GROUP):
        baseUri = config.API_ONE_AUTH;
        break;
      case getOperationName(GET_PEOPLES):
      case getOperationName(GET_PEOPLE_BIO_QUERY):
        baseUri = config.API_ONE_PEOPLE;
        break;
      case getOperationName(GET_PHOTOS):
        baseUri = config.API_ONE_PHOTO;
        break;
      case getOperationName(GET_RESOLVED_IMAGE):
      case getOperationName(GET_UPLOAD_URL):
      case getOperationName(GET_FOCUS_POINT):
        baseUri = config.API_IMAGE;
        break;
      default:
        baseUri = config.API_ONE_WEB;
        break;
    }

    return {
      uri: `${baseUri}?${operation.operationName}`,
    };
  });
  if (forward) {
    return forward(operation);
  }

  return null;
});

const authLink = setContext(async (_, context) => {
  const jwt = await auth.getJwt();
  if (!jwt) {
    return context;
  }

  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: `Bearer ${jwt}`,
      'x-client-name': `${config.ENV}-BONE-ARTICLE-REACT`,
      'x-client-version': config.VERSION,
    },
  };
});

const paginatedFields = ['users', 'articles', 'searchArticles', 'tags'];

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'TagProviderResult':
        return object.id as string;
      default:
        return defaultDataIdFromObject(object) || undefined;
    }
  },
  typePolicies: {
    Query: {
      fields: paginatedFields.reduce(
        (prev, fieldName) => ({
          ...prev,
          searchAffiliationProduct: {
            keyArgs: ['keyword', 'sort', 'minPrice', 'maxPrice'],
            merge: (existing: any, incoming: any, { args }: any) => {
              if (args.page === 0) {
                return incoming;
              }

              if (!existing) {
                return incoming;
              }

              return {
                ...(existing || {}),
                pageable: {
                  ...existing.pageable,
                  pageNumber:
                    incoming?.pageable?.pageNumber ||
                    existing?.pageable.pageNumber,
                },
                content: [
                  ...(existing?.content || []),
                  ...(incoming?.content || []),
                ],
              };
            },
          },
          [fieldName]: {
            keyArgs: ['where', 'first', 'orderBy'],
            merge: (existing = [], incoming: any, { args }: any) =>
              !args?.skip ? incoming : [...existing, ...incoming],
          },
        }),
        {},
      ),
    },
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([identifyRequestLink, authLink, httpLink]),
  cache,
  resolvers: {},
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});
