import { find } from 'ramda';
import { MediaBlock } from '../types/draft';
import { assert } from './assert';
import { getCropZoneParam, resizer } from './resizer';

export const getImageFromArticleBody = (
  body: string | null,
  withCrop: boolean,
): string | undefined => {
  if (body) {
    const parsed = JSON.parse(body);

    if (!Array.isArray(parsed)) {
      const media = find((block: MediaBlock) => block.data?.type === 'media')(
        parsed.blocks,
      );
      const path = assert(media, (m) => m.data.iframely.meta.url);
      if (!withCrop) {
        return resizer.fit({
          origin: path,
          width: 350,
          height: 150,
        });
      }
      const crop = assert(media, (m) => m.data.crop);
      return resizer.fit({
        origin: path,
        width: 350,
        height: 150,
        parameters: getCropZoneParam(crop),
      });
    }
  }
};
