import { Info, LocalOfferOutlined } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import {
  EditAppBar,
  NonEditableField,
  NotificationTypeEnum,
  OneMedia,
  PageWithDrawer,
  useAdvice,
  useNotification,
} from '@prismamedia/one-components';
import { RawDraftContentState } from 'draft-js';
import { isEqual } from 'lodash';
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  BrandKey,
  CardStatus,
  GetTagForTagEdit_tag_cardTags_card,
} from '../../__generated__/queries-web';
import { Draft } from '../../components/Draft';
import { actions as DraftActions } from '../../components/Draft/DraftActions';
import { MediaInput } from '../../components/MediaInput';
import { MEDIA_TYPES } from '../../components/MediaInput/constants';
import { TagIcons } from '../../components/Tags/TagItem/Thumbnail/constants';
import { paths } from '../../routing/Router/paths';
import { formatDate } from '../../utils/dateUtils';
import { AdvicePopper } from '../ArticleEdit/ArticleEditor/AdvicePopper';
import {
  useCreateCard,
  useGetTags,
  useUpdateCard,
} from './tagEdit.web.graphql';

const getEmptyCard = (): GetTagForTagEdit_tag_cardTags_card => ({
  __typename: 'Card',
  id: '',
  body: null,
  media: null,
  createdAt: '',
  updatedAt: '',
});

export const TagEdit: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { pushNotification } = useNotification();
  const { openAdvice } = useAdvice();
  const textFieldRef = useRef(null);
  const emptyCard = useMemo(() => getEmptyCard(), []);
  const [card, setCard] = useState<GetTagForTagEdit_tag_cardTags_card>(
    emptyCard,
  );
  const { id, brandKey } = useParams<{ id: string; brandKey: BrandKey }>();
  const isCreate = !card.id;
  const { data, loading, error } = useGetTags({ id, brandKey });
  const dataCard = data?.tag?.cardTags[0]?.card;
  const [saveLoading, setSaveLoading] = useState(false);
  const [createCard] = useCreateCard();
  const [updateCard] = useUpdateCard();
  const itemDiff = useMemo(
    () => !isEqual(card, isCreate ? emptyCard : dataCard),
    [card, dataCard, emptyCard, isCreate],
  );
  const Icon = (data?.tag && TagIcons[data?.tag.type]) || LocalOfferOutlined;

  const medias = useMemo(() => {
    try {
      return JSON.parse(card.media || '') as OneMedia[];
    } catch (e) {
      return [];
    }
  }, [card.media]);

  const body = useMemo(() => {
    try {
      return JSON.parse(card.body || '') as RawDraftContentState | null;
    } catch (e) {
      return null;
    }
  }, [card.body]);

  const setMedias = (setter: SetStateAction<OneMedia[]>) => {
    const newMedias = typeof setter === 'function' ? setter(medias) : setter;
    setCard((prev) => ({ ...prev, media: JSON.stringify(newMedias) }));
  };

  const setBody = (value: RawDraftContentState) => {
    setCard((prev) => ({ ...prev, body: JSON.stringify(value) }));
  };

  useEffect(() => {
    dataCard && setCard(dataCard);
  }, [dataCard, emptyCard, isCreate]);

  const onSave = async () => {
    if (data?.tag) {
      setSaveLoading(true);
      try {
        if (isCreate) {
          await createCard({
            variables: {
              data: {
                brandKey,
                status: CardStatus.Published,
                title: '',
                url: '',
                label: '',
                slug: '',
                body: card.body,
                media: card.media,
                cardTags: {
                  create: [
                    {
                      order: 0,
                      tag: { connect: { id: data.tag.id } },
                    },
                  ],
                },
              },
            },
          });
        } else {
          await updateCard({
            variables: {
              where: { id: card.id },
              data: { body: card.body, media: card.media },
            },
          });
        }
      } catch (e) {
        pushNotification({
          message: (e as Error).message,
          type: NotificationTypeEnum.error,
        });
      }
      setSaveLoading(false);
    }
  };

  const handleInfoIconClick = useCallback(
    (anchorRef, content) => () => {
      openAdvice({
        anchorRef,
        content,
        PopperProps: {
          keepMounted: false,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundariesElement: 'window',
                enabled: false,
                escapeWithReference: true,
              },
            },
          ],
          style: { width: '310px' },
        },
        zIndex: 1,
      });
    },
    [openAdvice],
  );

  return (
    <>
      <EditAppBar
        title={data?.tag?.title}
        onNavigateBack={() => history.push(paths.TAG_LIST)}
        save={{
          disabled: !itemDiff,
          loading: saveLoading,
          onClick: onSave,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={error || (!data?.tag ? Error("Ce tag n'existe pas") : undefined)}
        rightDrawer={
          <>
            <NonEditableField
              label="Id du tag"
              value={data?.tag?.id}
              sx={{ mb: 2 }}
            />
            <NonEditableField label="Marque" value={brandKey} sx={{ mb: 2 }} />
            <NonEditableField
              label="Statut"
              value={
                <Typography
                  sx={{
                    color: data?.tag?.tagBrandUsages[0]?.isEnabled
                      ? undefined
                      : theme.palette.error.main,
                  }}
                >
                  {data?.tag?.tagBrandUsages[0]?.isEnabled
                    ? 'Actif'
                    : 'Inactif'}
                </Typography>
              }
              sx={{ mb: 2 }}
            />
            <NonEditableField
              label="Nombre d'articles liés"
              value={data?.tag?.tagBrandUsages[0]?.articleCount}
              sx={{ mb: 2 }}
            />
            <NonEditableField
              label="Type"
              value={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon sx={{ fontSize: 16, mr: 1 }} />
                  <Typography>{data?.tag?.type}</Typography>
                </Box>
              }
              sx={{ mb: 2 }}
            />
            <NonEditableField
              label="Langue"
              value={data?.tag?.lang}
              sx={{ mb: 2 }}
            />
            <NonEditableField
              label="Merge"
              value={data?.tag?.mergedTo}
              sx={{ mb: 2 }}
            />
            {dataCard?.createdAt && (
              <NonEditableField
                label="Date de création"
                value={formatDate(dataCard?.createdAt, 'dd/MM/yyyy à HH:mm')}
                sx={{ mb: 2 }}
              />
            )}
            {dataCard?.updatedAt && (
              <NonEditableField
                label="Date de modification"
                value={formatDate(dataCard?.updatedAt, 'dd/MM/yyyy à HH:mm')}
                sx={{ mb: 2 }}
              />
            )}
          </>
        }
      >
        <MediaInput
          medias={medias}
          setMedias={setMedias}
          brandKey={brandKey}
          allowedMedias={[MEDIA_TYPES.IMAGE]}
          sx={{ mb: 4 }}
          disableFields
        />
        <Draft
          rawDraft={body}
          onChange={setBody}
          actions={[DraftActions.BOLD, DraftActions.ITALIC, DraftActions.LINK]}
          placeholder={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box>Texte</Box>
              <Info
                sx={{ marginLeft: '0.5rem', color: 'black' }}
                onClick={handleInfoIconClick(
                  textFieldRef,
                  <AdvicePopper
                    accordions={[
                      {
                        details: `Les pages de tags permettent de regrouper les articles traitant d'une même thématique.\nLe texte d'introduction de chaque page tag doit présenter cette thématique et encourager les internautes à explorer les articles associés.\nLe contenu doit rester sous la limite de 300 caractères, en étant concis et engageant pour le lecteur.`,
                        summary: 'Texte inférieur à 300 caractères',
                      },
                    ]}
                    title="Conseil de saisie"
                  />,
                )}
                ref={textFieldRef}
              />
            </Box>
          }
        />
      </PageWithDrawer>
    </>
  );
};
