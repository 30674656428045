import { gql, useMutation, useQuery } from '@apollo/client';
import {
  BrandKey,
  CreateCardForTagEdit,
  CreateCardForTagEditVariables,
  GetTagForTagEdit,
  GetTagForTagEditVariables,
  UpdateCardForTagEdit,
  UpdateCardForTagEditVariables,
} from '../../__generated__/queries-web';

const CARD = gql`
  fragment Card on Card {
    id
    createdAt
    updatedAt
    body
    media
  }
`;

const GET_TAG = gql`
  query GetTagForTagEdit(
    $where: TagWhereUniqueInput!
    $tagBrandUsagesWhere: TagBrandUsageWhereInput
    $cardTagsWhere: CardTagWhereInput
  ) {
    tag(where: $where) {
      id
      title
      slug
      type
      lang
      mergedTo
      tagBrandUsages(first: 1, where: $tagBrandUsagesWhere) {
        isEnabled
        articleCount
      }
      cardTags(first: 1, where: $cardTagsWhere) {
        card {
          ...Card
        }
      }
    }
  }
  ${CARD}
`;

export const useGetTags = ({
  id,
  brandKey,
}: {
  id: string;
  brandKey: BrandKey;
}) =>
  useQuery<GetTagForTagEdit, GetTagForTagEditVariables>(GET_TAG, {
    variables: {
      where: {
        id,
      },
      tagBrandUsagesWhere: {
        brandKey,
      },
      cardTagsWhere: {
        card: {
          brandKey,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

const CREATE_CARD = gql`
  mutation CreateCardForTagEdit($data: CardCreateInput!) {
    createCard(data: $data) {
      ...Card
    }
  }
  ${CARD}
`;

export const useCreateCard = () =>
  useMutation<CreateCardForTagEdit, CreateCardForTagEditVariables>(
    CREATE_CARD,
    { refetchQueries: [GET_TAG] },
  );

const UPDATE_CARD = gql`
  mutation UpdateCardForTagEdit(
    $data: CardUpdateInput!
    $where: CardWhereUniqueInput!
  ) {
    updateCard(data: $data, where: $where) {
      ...Card
    }
  }
  ${CARD}
`;

export const useUpdateCard = () =>
  useMutation<UpdateCardForTagEdit, UpdateCardForTagEditVariables>(UPDATE_CARD);
