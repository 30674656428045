import { gql } from '@apollo/client';

export const AUTHOR_FRAGMENT = gql`
  fragment AuthorFragment on Author {
    avatarUrl
    brandKey
    description
    facebookId
    googleId
    hasPublicProfile
    id
    instagramId
    isMain
    isExternal
    linkedinId
    media
    name
    pinterestId
    snapshatId
    twitterId
    userId
    resume
    email
    media
    authorHistories(first: 1000) {
      updatedAt
      createdAt
      id
      user {
        id
        name
        avatarUrl
      }
    }
    authorAttributes(first: 100) {
      order
      attribute {
        id
        title
      }
    }
  }
`;
