import { SxProps } from '@mui/material';
import {
  MediaType,
  MultimediaManager2,
  NotificationTypeEnum,
  OneMedia,
  getImageUrl,
  handleDrop,
  isImage,
  useNotification,
} from '@prismamedia/one-components';
import { ArticleFormat } from '@prismamedia/one-components/build/types/MultimediaManager/MultimediaPlugin/types';
import { RawDraftContentState } from 'draft-js';
import { move } from 'ramda';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { useDailymotionUploadURLGetter } from '../../apollo/queries/dailymotion.web.graphql';
import { useFocusPointGetter } from '../../apollo/queries/focusPoint.image.graphql';
import { AdditionnalMediaActions } from './AdditionnalMediaActions';
import { MediaFields } from './MediaFields';
import { MediaUploaders } from './MediaUploaders';
import { MEDIA_TYPES } from './constants';
import { postImages, postVideo } from './utils';

export const MediaInput: FC<{
  title?: string;
  medias: OneMedia[];
  setMedias: Dispatch<SetStateAction<OneMedia[]>>;
  brandKey: BrandKey;
  allowedMedias: MEDIA_TYPES[];
  subjectId?: string;
  videoId?: string;
  disableFields?: boolean;
  articleLead?: RawDraftContentState | null;
  articleTitle?: string;
  articleFormat?: ArticleFormat;
  sx?: SxProps;
}> = ({
  title,
  medias,
  setMedias,
  brandKey,
  allowedMedias,
  subjectId,
  videoId,
  disableFields,
  articleLead,
  articleTitle,
  articleFormat,
  sx,
}) => {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const getFocusPoint = useFocusPointGetter();
  const getDailymotionUploadURL = useDailymotionUploadURLGetter();
  const imageUploadAllowed = allowedMedias.includes(MEDIA_TYPES.IMAGE);
  const videoUploadAllowed = allowedMedias.includes(MEDIA_TYPES.DAILYMOTION);
  const [loader, setLoader] = useState<JSX.Element | undefined>();
  const mediaHasError = (media: OneMedia) =>
    !disableFields &&
    isImage(media) &&
    (!(media.credit || media.iframely.meta.credit) || !media.source);

  const selectedMedia = medias[selectedMediaIndex];

  const updateSelectedMedia: Dispatch<SetStateAction<OneMedia>> = (updater) => {
    setMedias((prev) =>
      prev.map((media, i) => {
        if (i !== selectedMediaIndex) return media;
        return typeof updater === 'function' ? updater(media) : updater;
      }),
    );
  };

  const fetchFocusPoint = async (media: OneMedia) => {
    const imageUrl = getImageUrl(media);
    const imageHeight = media.iframely.meta.height;
    const imageWidth = media.iframely.meta.width;
    try {
      const res = await getFocusPoint({
        imageUrl,
        imageHeight,
        imageWidth,
      });
      const { x, y, relativeX, relativeY } = res.getFocusPoint;
      return { x, y, relativeX, relativeY };
    } catch (e) {
      return undefined;
    }
  };

  const onUploadFiles = async (files: File[]) => {
    if (
      files.some((file) => file.type.includes('video')) &&
      !videoUploadAllowed
    ) {
      pushNotification({
        message: "L'import de vidéos n'est pas autorisé",
        type: NotificationTypeEnum.error,
      });
      return;
    }

    if (
      !files.every((file) => file.type.includes('video')) &&
      !imageUploadAllowed &&
      videoUploadAllowed
    ) {
      pushNotification({
        message: "Seules les vidéos sont autorisées à l'import",
        type: NotificationTypeEnum.error,
      });
      return;
    }

    if (
      files.some(
        (file) => file.type.includes('video') && file.size > 10485760 * 100,
      )
    ) {
      pushNotification({
        message: 'La vidéo doit faire moins de 1 Go',
        type: NotificationTypeEnum.error,
      });
      return;
    }

    setLoading(true);
    try {
      const newImages = await handleDrop({
        files: files.filter((file) => !file.type.includes('video')),
        mediaType: MediaType.Image,
        disableImgWidth: true,
        handleFiles: postImages(brandKey),
        pushNotification,
      });

      const focusPoints = await Promise.all(
        newImages.map((media) => fetchFocusPoint(media)),
      );

      const newImagesWithFocusPoint = newImages.map((media, i) => ({
        ...media,
        focusPoint: focusPoints[i],
      }));

      const newVideos = await Promise.all(
        files
          .filter((file) => file.type.includes('video'))
          .map((file) => postVideo(file, brandKey, getDailymotionUploadURL)),
      );

      const lastMediaIndex = medias.length - 1;
      setMedias((prev) => [...prev, ...newImagesWithFocusPoint, ...newVideos]);
      setSelectedMediaIndex(lastMediaIndex + 1);
    } catch (error) {
      pushNotification({
        message: (error as Error).message || 'Média non reconnu',
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  };

  return (
    <MultimediaManager2
      title={title}
      medias={medias}
      loading={loading}
      sx={sx}
      onMediaDelete={(index) =>
        setMedias((prev) => prev.filter((_, i) => i !== index))
      }
      onMediaReorder={(oldIndex, newIndex) =>
        setMedias((prev) => move(oldIndex, newIndex, prev))
      }
      onCropAndFocusPointChange={({ crop, focusPoint }, index) =>
        setMedias((prev) =>
          prev.map((media, i) =>
            i === index ? { ...media, focusPoint, crop } : media,
          ),
        )
      }
      loader={loader}
      mediaWithErrorIndexes={medias.reduce<number[]>(
        (prev, media, i) => (mediaHasError(media) ? [...prev, i] : prev),
        [],
      )}
      selectedMediaIndex={selectedMediaIndex}
      setSelectedMediaIndex={setSelectedMediaIndex}
      onUploadFiles={
        imageUploadAllowed || videoUploadAllowed ? onUploadFiles : undefined
      }
      mediaUploaders={
        <MediaUploaders
          medias={medias}
          setMedias={setMedias}
          brandKey={brandKey}
          setSelectedMediaIndex={setSelectedMediaIndex}
          allowedMedias={allowedMedias}
          subjectId={subjectId}
          videoId={videoId}
          setLoading={setLoading}
          articleTitle={articleTitle}
          articleLead={articleLead}
          setLoader={setLoader}
          articleFormat={articleFormat}
        />
      }
      mediaFields={
        !disableFields && (
          <MediaFields
            media={selectedMedia}
            setMedia={updateSelectedMedia}
            brandKey={brandKey}
          />
        )
      }
      additionnalMediaActions={
        <AdditionnalMediaActions
          media={selectedMedia}
          setMedia={updateSelectedMedia}
          setLoading={setLoading}
        />
      }
    />
  );
};
